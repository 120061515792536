.events .content {
  font-size: 15px;
}

.events {
  margin-bottom: 3rem;
}

.events .content {
  padding: 0;
}

@media screen and (max-width: 320px) {
  .events div.content {
    font-size: 15px;
  }
}

@media screen and (min-width: 900px) {
  .events .content {
    padding: 0px 80px;
  }

  .events .banner .banner__text {
    top: 50%;
    left: 23%;
  }
}

@media screen and (min-width: 1200px) {
  .events .content {
    padding: 0px 150px;
  }
}

@media screen and (min-width: 1800px) {
  .events .content {
    padding: 0px 250px;
  }

  .events .banner .banner__text {
    left: 20%;
  }

  .events .banner .banner__text .banner__text-bold {
    font-size: 75px;
  }
}
