.hcp__video-container--1,
.hcp__video-container--2 {
  margin-top: 50px;
}

.testimonial .content {
  padding: 0px 30px 0px 30px;
}

@media screen and (min-width: 600px) {
  .testimonial .content {
    padding: 0px 100px 0px 100px;
  }
}

@media screen and (min-width: 900px) {
  .hcp__video-container--2 {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
    flex-direction: row;
  }

  .hcp__video-container--2 .testimonial__content--2 {
    width: 48%;
  }

  .hcp__div-review {
    padding: 40px;
    margin: 0 auto;
  }

  .testimonial .content {
    padding: 0px 150px 0px 150px;
  }

  .testimonial .banner .banner__text {
    text-align: left;
    top: 50%;
  }
}

@media screen and (min-width: 1200px) {
  .hcp__div-review {
    padding: 60px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1800px) {
  .testimonial .content {
    padding: 0px 275px 0px 275px;
  }
}
