.clinicalstudiestile__tile {
  border-radius: 5px;
}

.clinicalstudiestile__tile-left {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.clinicalstudiestile__tile-right {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.summary__dropdown {
  visibility: hidden;
  height: 0px;
  font-size: 15px;
}

.clinicalstudiestile__link {
  text-decoration: none;
}

.summary__label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  font-weight: 600;
}

.summary-expansion-arrow {
  stroke: #707070;
  stroke-width: 1.75;
  font-size: 23px;
  margin-left: 8px;
}

.summary__checkbox ~ label .summary-expansion-arrow {
  transform: rotate(0deg);
  transition: all 0.4s;
}

.summary__checkbox:checked ~ label .summary-expansion-arrow {
  transform: rotate(-180deg) !important;
}

.summary__checkbox:checked ~ .summary__dropdown {
  visibility: visible;
  height: auto;
  width: 100%;
  animation: slideDown 0.3s ease-in-out;
}

.summary__dropdown::before {
  content: open-quote;
}

.summary__dropdown::after {
  content: close-quote;
}

input.summary__checkbox[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.clinicalstudiestile__tile {
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
  color: #686868;
  margin-bottom: 30px;
}

.clinicalstudiestile__tile-content {
  display: none;
}

.clinicalstudiestile__desktop-only {
  display: none;
  visibility: none;
}

.clinicalstudiestile__mobile-only {
  display: block;
  visibility: visible;
}

.clinicalstudiestile__tile-button {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: #365b7e;
  color: #ffffff;
  font-size: 17px;
  width: 140px;
  height: 50px;
  margin-bottom: 10px;
  font-weight: 600;
}

.clinicalstudiestile__tile-left {
  padding: 30px;
  padding-bottom: 0;
}

.clinicalstudiestile__tile-right {
  padding: 30px;
}

.clinicalstudiestile__mobile-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  flex-direction: column;
}

.clinicalstudiestile__tile-left-title {
  font-size: 20px;
  font-weight: 700;
  color: #365b7e;
}

.clinicalstudiestile__date-mobile {
  font-size: 20px;
  color: #365b7e;
  font-weight: 600;
}

.clinicalstudiestile__tile-right-title {
  line-height: 30px;
  font-size: 15px;
}

.clinicalstudiestile__tile-doctors {
  font-weight: 600;
  font-size: 15px;
}

.clinicalstudies .content {
  padding: 0;
}

@media screen and (min-width: 600px) {
  .clinicalstudiestile__tile-right-title,
  .clinicalstudiestile__tile-doctors,
  .summary__dropdown {
    font-size: 17px;
  }
}

@media (min-width: 900px) {
  .clinicalstudiestile__tile {
    display: flex;
    flex-direction: row;
  }

  .clinicalstudiestile__tile-left {
    width: 45%;
    padding: 60px;
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  .clinicalstudiestile__tile-right {
    width: 70%;
    padding: 60px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .clinicalstudiestile__tile-right-title {
    font-size: 18px;
  }

  .clinicalstudiestile__tile-date,
  .clinicalstudiestile__tile-left-title {
    font-size: 25px;
  }

  .clinicalstudiestile__tile-content {
    display: block;
    font-size: 16px;
  }

  .clinicalstudiestile__tile-content::before {
    content: open-quote;
    font-weight: 600;
  }

  .clinicalstudiestile__tile-content::after {
    content: close-quote;
    font-weight: 600;
  }

  .clinicalstudiestile__mobile-only {
    display: none;
    visibility: none;
  }

  .clinicalstudiestile__desktop-only {
    display: block;
    visibility: visible;
  }

  .clinicalstudiestile__tile-button {
    height: 50px;
    width: 150px;
    font-size: 20px;
  }

  .clinicalstudiestile__tile-doctors {
    font-size: 16px;
  }

  .clinicalstudies .content {
    padding: 0px 80px;
  }
}

@media screen and (min-width: 1200px) {
  .clinicalstudiestile__tile-left {
    width: 40%;
  }

  .clinicalstudies .content {
    padding: 0px 150px;
  }

  .clinicalstudiestile__tile-button {
    height: 50px;
    width: 160px;
    font-size: 22px;
  }

  .clinicalstudiestile__tile-left-title,
  .clinicalstudiestile__tile-date {
    line-height: 40px;
    font-size: 28px;
  }

  .clinicalstudiestile__tile-right-title {
    font-size: 20px;
  }

  .clinicalstudiestile__tile-doctors,
  .clinicalstudiestile__tile-content {
    font-size: 18px;
  }
}

@media screen and (min-width: 1800px) {
  .clinicalstudies .content {
    padding: 0px 250px;
  }

  .clinicalstudiestile__tile-doctors,
  .clinicalstudiestile__tile-content {
    font-size: 20px;
  }

  .clinicalstudiestile__tile-right-title {
    font-size: 22px;
    line-height: 40px;
  }

  .clinicalstudiestile__tile-left-title,
  .clinicalstudiestile__tile-date {
    font-size: 30px;
    max-width: 260px;
  }

  .clinicalstudiestile__tile-left {
    width: 30%;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
