.modal__background {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: all 0.6s ease-in;
  opacity: 0;
  z-index: -1;
  backface-visibility: hidden;
}

.modal__background.active {
  opacity: 1;
  z-index: 99999;
}

.modal .btn-link {
  width: 100%;
}

.modal__container {
  background-color: white;
  width: 95%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 14%;
  border-radius: 5px;
  padding: 40px;
}

.modal__button-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 40%;
  justify-content: center;
  width: 100%;
}

.modal__btn-left-container {
  margin-bottom: 10px;
}

.modal__header {
  text-align: center;
  margin-bottom: 20px;
}

.header--primary--blue.modal__header--primary {
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Comfortaa";
}

.header--secondary--blue.modal__header--secondary {
  font-size: 20px;
  font-weight: 600;
  margin-top: 0;
}

.header--primary--blue.modal__header--primary,
.header--secondary--blue.modal__header--secondary {
  color: #365b7e;
}

.modal__btn--left,
.modal__btn--right {
  width: 100%;
  background-color: #365b7e;
  color: #ffffff;
  height: 30%;
  border: none;
  border-radius: 4px;
  font-weight: 600;
}

.modal__background.active ~ *:not(.home):not(.notFound) {
  filter: blur(10px);
}

.modal__background.active + .home > .banner .banner__text,
.modal__background.active + .notFound > .banner .banner__text {
  position: fixed;
  filter: blur(10px);
}

.modal__background.active + .home > .banner .banner__img,
.modal__background.active + .notFound > .banner .banner__img {
  position: fixed;
  filter: blur(10px);
}

.modal__background.active + .home + .footer-container,
.modal__background.active + .notFound + .footer-container {
  position: fixed;
  filter: blur(10px);
}

.modal__background .btn {
  min-width: 280px;
  margin-right: none;
}

.modal__background .btn {
  height: 40px;
  font-size: 15px;
}

@media screen and (min-width: 600px) {
  h1.header--primary--blue.modal__header--primary {
    font-size: 40px;
  }

  h2.header--secondary--blue.modal__header--secondary {
    font-size: 30px;
  }

  .modal__btn--left,
  .modal__btn--right {
    font-size: 20px;
    height: 35%;
    width: 60%;
  }
  .modal__background .btn {
    width: 320px;
  }

  .modal__btn-left-container,
  .modal__btn-right-container {
    width: auto;
  }
}

@media screen and (min-width: 900px) {
  .modal__header h1.header--primary--blue.modal__header--primary {
    font-size: 50px;
  }

  .modal__header h2.header--secondary--blue.modal__header--secondary {
    font-size: 35px;
  }

  div.modal__button-container {
    flex-direction: row;
  }

  .modal__btn-left-container {
    margin-bottom: 0;
  }

  div.modal__container {
    justify-content: center;
  }

  .modal__btn--left,
  .modal__btn--right {
    height: 40%;
    width: 45%;
  }

  .modal__background .btn {
    height: 50px;
    font-size: 18px;
  }

  .modal__btn-left-container {
    margin-right: 50px;
  }
}

@media screen and (min-width: 1200px) {
  div.modal__container {
    padding: 60px;
    width: 65%;
    justify-content: center;
  }

  .modal__header h1.header--primary--blue.modal__header--primary {
    font-size: 70px;
  }

  .modal__header h2.header--secondary--blue.modal__header--secondary {
    font-size: 38px;
  }

  h1.header--primary--blue.modal__header--primary {
    margin-bottom: 0;
  }

  .modal__header {
    text-align: center;
    margin-bottom: 30px;
  }
}
