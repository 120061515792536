.hcp-modal-background {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  transition: opacity 0.3s ease, z-index 0.3s ease;
  opacity: 0;
  z-index: -1;
  backface-visibility: hidden;
}

.hcp-modal-background.active {
  opacity: 1;
  z-index: 1000;
  transition: opacity 0.9s ease, z-index 0.9s ease;
  /* backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); */
}

.hcp-modal-background.active ~ *:not(.home):not(.notFound) {
  filter: blur(10px);
}

.hcp-modal-background.active ~ .home > .banner .banner__text,
.hcp-modal-background.active ~ .notFound > .banner .banner__text {
  position: fixed;
  filter: blur(10px);
}

.hcp-modal-background.active ~ .home > .banner .banner__img,
.hcp-modal-background.active ~ .notFound > .banner .banner__img {
  position: fixed;
  filter: blur(10px);
}

.hcp-modal-background.active ~ .home + .footer-container,
.hcp-modal-background.active ~ .notFound + .footer-container {
  position: fixed;
  filter: blur(10px);
}

@media screen and (min-width: 1px) {
  .hcp-modal-container {
    background-color: white;
    width: 80%;
    min-height: 350px;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 22%;
    border-radius: 15px;
    padding: 20px;
  }

  .hcp-modal-header {
    font-size: 21px;
    font-weight: 600;
    color: #365b7e;
    margin-bottom: 15px;
    text-align: center;
  }

  .hcp-disclaimer {
    margin-bottom: 20px;
    font-size: 14px;
  }

  .hcp-modal-options-container {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .selection-label {
    font-weight: 700;
    color: rgba(51, 51, 51, 0.8);
    margin-left: 8px;
  }

  .radio-button-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 600px) {
  .hcp-modal-container {
    padding: 35px 40px;
    width: 80%;
    height: 420px;
    align-items: normal;
  }

  .hcp-modal-header {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .hcp-disclaimer {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .hcp-modal-options-container {
    height: 90px;
    font-size: 16px;
  }
}

@media screen and (min-width: 900px) {
  .hcp-modal-container {
    padding: 35px 60px;
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
  .hcp-modal-container {
    width: 720px;
    height: 450px;
  }
}
