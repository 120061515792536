.header--primary--blue.notFound__header {
  text-transform: none;
}

.notFound .content {
  margin-top: 0;
  position: absolute;
  top: 30%;
  text-align: center;
}

.notFound .banner .banner__text .banner__text-bold {
  margin-bottom: 0.5rem;
  font-size: 45px;
}

.notFound .banner .banner__text .banner__btn-container {
  margin-top: 25px;
}

.notFound .banner .banner__text {
  top: 23%;
}

@media screen and (min-width: 600px) {
  .notFound .banner .banner__text .banner__text-bold {
    font-size: 65px;
  }

  .notFound .banner .banner__text {
    top: 23%;
  }
}

@media screen and (min-width: 900px) {
  .notFound .banner .banner__text {
    top: 40%;
    left: 52%;
    text-align: left;
  }

  .notFound .banner {
    position: static;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .notFound .banner .banner__img--desktop {
    min-width: 900px;
    min-height: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    image-rendering: -webkit-optimize-contrast;
  }

  .notFound + .footer-container {
    position: fixed;
    bottom: 0;
  }

  .notFound .banner .banner__text .banner__text-normal {
    font-size: 25px;
  }
}

@media screen and (min-width: 1200px) {
  .notFound div.content {
    top: 40%;
    text-align: left;
  }

  .notFound .banner .banner__text {
    left: 54%;
  }

  .notFound .banner .banner__text .banner__text-normal {
    font-size: 28px;
  }

  .notFound .banner .banner__text .banner__text-bold {
    font-size: 80px;
  }
}

@media screen and (min-width: 1800px) {
  .notFound .banner .banner__text .banner__text-normal {
    font-size: 30px;
  }

  .notFound .banner .banner__text .banner__text-bold {
    font-size: 90px;
  }
}
