@media screen and (min-width: 1px) {
  .skeleton.doctor-header {
    width: 60%;
    height: 14px;
    margin-top: 0px;
  }

  .skeleton.location {
    width: 80%;
    height: 12px;
  }

  .skeleton.address-1 {
    width: 80%;
    height: 12px;
  }

  .skeleton.address-2 {
    width: 50%;
    height: 12px;
  }

  .skeleton.phone-link {
    width: 55%;
    height: 12px;
  }

  .surgeon_skeleton_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
    background-color: #fafafa;
    border-radius: 5px;
    height: 200px;
    overflow: hidden;
  }

  .skeleton.surgeon-img {
    width: 40%;
    margin: 0;
  }

  .surgeon_skeleton_info {
    width: 55%;
    padding-top: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media screen and (min-width: 500px) {
  .surgeon_skeleton_container {
    height: 215px;
  }
  .skeleton.surgeon-img {
    width: 30%;
  }

  .surgeon_skeleton_info {
    padding: 1rem;
    width: 70%;
  }
}

@media screen and (min-width: 900px) {
  .skeleton.doctor-header {
    height: 16px;
  }

  .skeleton.location {
    height: 14px;
  }

  .skeleton.address-1 {
    height: 14px;
  }

  .skeleton.address-2 {
    height: 14px;
  }

  .skeleton.phone-link {
    height: 14px;
  }
  .surgeon_skeleton_container {
    width: 700px;
    height: 235px;
  }
  .skeleton.surgeon-img {
    width: 25%;
  }
  .surgeon_skeleton_info {
    width: 75%;
  }
}

@media screen and (min-width: 1200px) {
  .surgeon_skeleton_container {
    width: 48%;
    height: 270px;
    margin-bottom: 3rem;
  }

  .skeleton.surgeon-img {
    width: 270px;
  }

  .surgeon_skeleton_info {
    padding-top: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 1800px) {
  .surgeon_skeleton_container {
    margin-bottom: 3.5rem;
  }

  .skeleton.surgeon-img {
    width: 300px;
  }

  .surgeon_skeleton_info {
    padding-top: 1.5rem;
  }
}
