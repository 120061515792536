.skeleton {
  background: #ddd;
  margin: 20px 0;
  border-radius: 4px;
}

@keyframes pulse {
  50% {
    opacity: 0.6;
  }
}

.pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes shimmer {
  from {
    background-position: 100% 0;
  }
  to {
    background-position: -100% 0;
  }
}
.shimmer {
  animation: shimmer 1.5s linear infinite;
  background: linear-gradient(
    to right,
    transparent 0%,
    rgba(0, 0, 0, 0.15) 25%,
    transparent 100%
  );
  background-size: 200% 100%;
}
