.home {
  height: 100%;
}

.home .btn {
  width: 280px;
  margin-right: none;
  margin-bottom: 20px;
  font-size: 15px;
  height: 40px;
}

.home .banner__text-bold {
  font-size: 26px;
  margin-bottom: 10px;
}

.home__sup {
  text-decoration: underline;
  font-size: 12px;
  font-weight: 300;
  position: absolute;
  top: 0%;
  right: -32px;
  font-family: "Comfortaa";
  padding-right: 5px;
}

.home__header-span {
  position: relative;
  font-family: "Comfortaa";
}

span.banner__text-normal {
  font-weight: 600;
}

.__react_component_tooltip {
  width: 500px;
}

.sup__style .multi-line {
  text-align: left !important;
}

.home .banner .banner__text {
  position: absolute;
  top: 30%;
  left: 50%;
}

.home .btn {
  height: 40px;
  font-size: 15px;
}

.home .banner .banner__text .banner__text-normal .br__mobile-only--text-1 {
  display: block;
}

.br__mobile-only--bold-text {
  display: block;
}

/* .home .banner .banner__text .home__header-span {
  visibility: visible;
} */

@media screen and (min-width: 300px) {
  .home .banner__text-bold {
    font-size: 22px;
  }
}

@media screen and (min-width: 317px) {
  .home .banner .banner__text .banner__text-normal .br__mobile-only--bold-text {
    display: none;
  }
}

@media screen and (min-width: 330px) {
  .home .banner__text-bold {
    font-size: 22px;
  }
}

@media screen and (min-width: 400px) {
  .home .banner__text-bold {
    font-size: 29px;
  }
}

@media screen and (min-width: 450px) {
  /* .home .banner .banner__text .banner__text-normal .br__mobile-only--text-1 {
    display: none;
  } */
}

@media screen and (min-width: 600px) {
  .home .banner .banner__text {
    position: absolute;
    top: 25%;
    left: 50%;
  }

  .home .banner__text-bold {
    font-size: 43px;
  }
}

@media screen and (min-width: 900px) {
  .home .banner {
    position: static;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .home .banner .banner__text {
    position: absolute;
    top: 38%;
    left: 48%;
    text-align: left;
  }

  .home .btn {
    margin-right: 30px;
    width: 280px;
  }

  .home .banner .banner__text .banner__btn-container {
    justify-content: flex-start;
  }

  .home .banner .banner__img--desktop {
    min-width: 900px;
    min-height: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    image-rendering: -webkit-optimize-contrast;
  }

  .home + .footer-container {
    position: fixed;
    bottom: 0;
  }

  .home .banner__text-bold {
    font-size: 45px;
  }
}

@media screen and (min-width: 1200px) {
  .home .btn {
    margin-right: 45px;
    height: 50px;
    font-size: 18px;
  }

  .home .banner__text-bold {
    font-size: 55px;
  }
}

@media screen and (min-width: 1800px) {
  .home .btn {
    width: 320px;
    height: 50px;
    margin-right: 40px;
  }

  .home .banner .banner__text {
    max-width: none;
    left: 50%;
  }

  .home .banner__text-bold {
    font-size: 64px;
  }
}
