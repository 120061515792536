.eventstile__tile-upcoming-past-banner {
  background-color: #eeeeee;
  padding: 20px 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.eventstile__tile-upcoming-past-banner + .eventstile__tile {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.eventstile__tile-upcoming-past-banner
  + .eventstile__tile
  .eventstile__tile-left,
.eventstile__tile-upcoming-past-banner
  + .eventstile__tile
  .eventstile__tile-right {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.eventstile__tile {
  border-radius: 5px;
}

.eventstile__tile-left {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
}

.eventstile__tile-right {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.eventstile__header-desktop--header {
  color: rgb(234, 97, 0) !important;
  font-size: 23px;
}

.eventtiles__paragraph {
  font-size: 17px;
  font-weight: 700;
}

.eventstile__tile-left-title.eventstile__desktop-only {
  display: none;
}

.eventstile__title-mobile.eventstile__mobile-only {
  font-size: 20px;
  display: flex;
  color: #365b7e;
}

.eventstile__header-mobile-bold {
  font-size: 20px;
  font-weight: 700;
  color: #365b7e;
}

.eventstile__tile {
  color: #686868;
  /* padding: 2rem 0 3rem; */
  padding-bottom: 2rem;
  background-color: #fafafa;
  margin-bottom: 30px;
}

.eventstile__tile-content.conference {
  color: #365b7e;
  padding-bottom: 10px;
}

.eventstile__tile-button {
  cursor: pointer;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  background-color: #365b7e;
  color: #ffffff;
  font-size: 17px;
  width: 150px;
  height: 50px;
  font-weight: 600;
  display: inline-block;
}

.disabled {
  cursor: not-allowed;
  background-color: gray;
  color: #ffffff;
}

.disabled:hover {
  background-color: gray !important;
}

.eventstile__paragraph {
  font-weight: 600;
}

.eventstile__tile-left,
.eventstile__tile-right {
  padding: 30px 30px 0 30px;
}

.eventstile__tile-right-title {
  font-size: 15px;
}

.eventstile__link {
  text-decoration: none;
}

@media screen and (min-width: 900px) {
  div.eventstile__tile-left-title.eventstile__desktop-only {
    display: initial;
  }

  .eventstile__title-mobile.eventstile__mobile-only {
    display: none;
  }

  div.eventstile__tile {
    display: flex;
    width: 100%;
  }

  .eventstile__tile .eventstile__tile-left {
    background-color: #f5f5f5;
    text-align: center;
    padding: 60px;
    width: 40%;
    display: flex;
    justify-content: center;
  }

  .eventstile__tile-left-title.eventstile__desktop-only img {
    width: 200px;
    object-fit: contain;
    margin-top: 1rem;
  }

  .eventstile__header-desktop,
  .eventstile__paragraph-desktop {
    font-size: 28px;
    color: #365b7e;
  }

  .eventstile__tile .eventstile__tile-right {
    background-color: #fafafa;
    padding: 60px;
    width: 60%;
  }

  p.eventstile__tile-content {
    font-size: 16px;
  }

  p.eventstile__paragraph {
    font-size: 18px;
  }

  .eventstile__tile-right-title {
    font-size: 20px;
    font-weight: 600;
  }

  div.eventstile__tile {
    background-color: transparent;
  }

  .eventstile__tile-button {
    height: 50px;
    width: 200px;
    font-size: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .eventstile__tile .eventstile__tile-left {
    width: 30%;
  }

  .eventstile__tile .eventstile__tile-right {
    width: 70%;
    line-height: 30px;
  }

  p.eventstile__tile-content {
    font-size: 18px;
  }

  p.eventstile__paragraph {
    font-size: 20px;
  }

  .eventstile__tile-right-title {
    font-size: 24px;
  }

  .eventstile__tile-button {
    height: 60px;
    font-size: 22px;
  }

  .eventstile__tile-upcoming-past-banner {
    padding: 20px 40px;
  }
}

@media screen and (min-width: 1800px) {
  p.eventstile__tile-content {
    font-size: 22px;
  }

  p.eventstile__paragraph {
    font-size: 25px;
  }

  .eventstile__tile-right-title {
    font-size: 28px;
  }

  .eventstile__header-desktop,
  .eventstile__paragraph-desktop {
    font-size: 38px;
  }
}
