.jwplayer.jw-flag-user-inactive:not(.jw-flag-media-playing) .jw-cursor-default,
.jwplayer .jw-cursor-default {
  cursor: default !important;
}

.jw-title-primary {
  display: none !important;
}

.video-description {
  padding: 20px 40px;
  font-size: 16px;
  font-weight: 600;
  color: #707070;
  background-color: #fafafa;
  height: 120px;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

@media (min-width: 1200px) {
  .video-description {
    padding: 20px 40px;
    font-size: 20px;
    height: 140px;
  }
}
