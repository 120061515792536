h1.header-primary {
  font-size: 20px;
  text-transform: uppercase;
  padding: 10px 0px 10px 0px;
  margin: 10px 0px 10px 0px;
  font-weight: 700;
}

h1.header-primary--orange {
  color: #ea6100;
}

h1.header-primary--blue {
  color: #365b7e;
}

h1.header-primary--comfortaa {
  font-family: "Comfortaa";
  line-height: 1.5;
  font-size: 25px;
}

p.paragraph,
li.paragraph {
  color: #707070;
  margin: 10px 0px 10px 0px;
}

div.content {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px 50px 0px 50px;
}

h2.header--secondary--blue {
  font-size: 16px;
  color: #365b7e;
  margin: 10px 0px 10px 0px;
  font-weight: 600;
  margin-top: -1rem;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.br__desktop-only {
  display: none;
}

.ref__style {
  text-decoration: underline;
  line-height: 1;
  font-size: 12px;
}

.sup__style {
  max-width: 60vw;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);
  z-index: 999999 !important;
}

.max-content {
  min-width: auto;
}

@media screen and (min-width: 600px) {
  h1.header-primary {
    font-size: 22px;
  }

  p.paragraph {
    font-size: 16px;
  }

  div.content {
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 0px 100px 0px 100px;
  }

  h2.header--secondary--blue {
    font-size: 20px;
  }

  .br__mobile-only {
    display: none;
  }
}

@media screen and (min-width: 900px) {
  div.content {
    padding: 0px 150px 0px 150px;
  }

  h1.header-primary--comfortaa {
    font-size: 40px;
    /* font-size: 38px; */
  }

  .br__desktop-only {
    display: block;
  }

  .max-content {
    min-width: max-content;
  }

  .sup__style {
    max-width: 40vw;
  }
}

@media screen and (min-width: 1200px) {
  h1.header-primary {
    font-size: 40px;
  }

  h2.header--secondary--blue {
    font-size: 22px;
  }

  div.content {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  p.paragraph,
  li.paragraph {
    font-size: 20px;
  }

  /* h1.header-primary--comfortaa {
    font-size: 40px;
  } */

  .sup__style {
    max-width: 25vw;
  }
}

@media screen and (min-width: 1800px) {
  div.content {
    padding: 0px 275px 0px 275px;
  }

  .sup__style {
    max-width: 20vw;
  }

  h1.header-primary--comfortaa {
    /* font-size: 60px; */
    font-size: 50px;
  }
}
