.card__container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
  background-color: #fafafa;
  border-radius: 5px;
}

.card__body {
  width: 86%;
  padding-top: 0.5rem;
  padding-right: 0.5rem;
}

.card__title--1,
.card__description {
  color: #707070;
}

a.card__link {
  color: #365b7e;
}

.card__title--2 {
  font-style: italic;
  font-weight: 500;
}

.card__title--1,
.card__title--2 {
  font-size: 11px;
}

.card__image {
  object-fit: contain;
  width: 90%;
}

.card__location {
  font-weight: 600;
}

.card__image-container {
  display: flex;
  width: 99%;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
}

.distance-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0%;
  color: white;
  background-color: #faa21bd9;
  width: 90%;
  height: 16%;
  text-align: center;
  font-weight: 400;
  font-size: 15px;
}

.card__description {
  margin-top: 0.5rem;
  font-size: 10px;
}

.card__additional {
  display: block;
}

@media screen and (min-width: 500px) {
  .card__body .card__title--1,
  .card__body .card__title--2 {
    font-size: 14px;
  }

  div.card__description {
    font-size: 12px;
  }

  div.card__body {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 1rem;
  }

  .card__image-container {
    width: 200px;
  }

  .card__image {
    width: 100%;
  }

  .card__body {
    width: 100%;
  }

  .distance-overlay {
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .card__body .card__title--1,
  .card__body .card__title--2 {
    font-size: 16px;
  }

  div.card__description {
    font-size: 14px;
  }

  .card__container {
    width: 700px;
  }
}

@media screen and (min-width: 1200px) {
  .card__container {
    width: 48%;
    margin-bottom: 3rem;
  }

  div.card__image-container {
    width: 270px;
  }

  div.card__description {
    font-size: 14px;
  }

  .card__body .card__title--1,
  .card__body .card__title--2 {
    font-size: 16px;
  }

  div.card__body {
    padding-top: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (min-width: 1800px) {
  div.card__image-container {
    width: 300px;
  }

  div.card__description {
    font-size: 18px;
  }

  .card__body .card__title--1,
  .card__body .card__title--2 {
    font-size: 20px;
  }

  div.card__body {
    padding-top: 1.5rem;
  }

  .card__description .card__location {
    padding-right: 5px;
  }

  .card__container {
    margin-bottom: 3.5rem;
  }
}
