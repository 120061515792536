* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.hcphome__img--1 {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.hcphome__section li {
    color: #ffffff;
}

.hcphome__gray-container {
    height: 400px;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
}

.hcphome__gray-container--1 {
    background-color: #fafafa;
}

.hcphome__gray-container--2 {
    background-color: #fafafa;
    text-align: right;
    justify-content: flex-end;
}

.hcphome__gray-container--3 {
    background-color: none;
    text-align: center;
}

.hcphome__gray-container--4 {
    height: auto;
    background-color: #fafafa;
    padding: 50px 50px 0px 50px;
}

.hcphome__button-container {
    display: flex;
    flex-direction: column;
}

.hcphome__button-container--3 {
    justify-items: center;
    margin-right: 0;
}

.hcphome__circle-svg--1 {
    display: block !important;
    position: absolute;
    top: 13%;
    right: -60%;
    width: 81%;
    transform: rotate(46deg);
    z-index: 999;
}

.hcphome__circle-svg--2 {
    display: block !important;
    position: absolute;
    top: 6%;
    left: -60%;
    width: 81%;
    transform: rotate(-30deg);
    z-index: 999;
}

.hcphome__testimonal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 40px;
}

.hcphome__testimonal-video {
    width: 100%;
    margin-bottom: 40px;
}

.hcphome .hcphome__gray-container--1 .btn {
    min-width: 265px;
}

.hcphome .hcphome__gray-container--3 .btn {
    width: 150px;
}

.hcphome__section {
    color: #ffffff;
    padding: 60px 60px;
    width: 100%;
}

.hcphome__section--one {
    background: #4b759d;
}

.hcphome__section--two {
    background: #365b7e;
}

.hcphome ul {
    margin-top: 20px;
}

.hcp__paragraph-li {
    color: #707070;
}

.hcphome ul li::marker {
    color: #ffa400;
}

.hcphome__section-container {
    display: flex;
    flex-direction: column;
}

.hcphome__testimonal-container {
    margin: 40px 0 40px 0;
}

.connectus__icon {
    margin-right: 10px;
    width: 20px;
}

.hcphome__button-container--3 .hcphome__button {
    background-color: #365b7e;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px 20px;
    color: #ffffff;
    font-size: 15px;
    border-radius: 3px;
    margin-bottom: 10px;
    font-weight: 600;
    cursor: pointer;
    margin: 0px 0px 10px 0px;
    height: 40px;
    width: 130px;
}

.hcphome__subheader {
    margin-bottom: 40px;
    font-size: 16px;
    color: #707070;
}

.hcphome__right-content {
    width: 100%;
}

.hcphome ul li.paragraph {
    margin: 0px 0px 0px 20px;
    line-height: 2;
}

.hcphome__section-header .ref__style {
    font-weight: 400;
}

#ACOG {
    scroll-margin-top: 100px;
}

@media screen and (min-width: 600px) {
    .hcphome__gray-container--4 {
        padding: 50px 100px 0px 100px;
    }

    .hcphome__circle-svg--1 {
        top: -5%;
        right: -60%;
        width: 81%;
    }

    .hcphome__circle-svg--2 {
        top: -13%;
        left: -60%;
        width: 81%;
    }
}

@media screen and (min-width: 900px) {
    .hcphome .banner .banner__text {
        top: 50%;
        text-align: left;
    }

    .hcphome__testimonal-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .hcphome__testimonal-video {
        width: 48%;
        margin-bottom: 40px;
    }

    .hcphome__button-container {
        flex-direction: row;
    }

    .hcphome__circle-svg--1 {
        top: -44%;
        right: -29%;
        width: 60%;
        transform: rotate(0deg);
        height: 125%;
    }

    .hcphome__circle-svg--2 {
        top: -44%;
        left: -29%;
        width: 60%;
        height: 125%;
        transform: rotate(0deg);
    }

    .hcphome__img--1 {
        height: 100%;
    }

    .hcphome__gray-container {
        height: 470px;
    }

    .hcphome__section-container {
        flex-direction: row;
    }

    .hcphome__section {
        padding: 100px 100px;
    }

    .hcphome__button-container--3 {
        justify-content: center;
    }

    .hcphome__button-container--3 .btn {
        margin: 25px;
    }

    .hcphome__button-container--3 .hcphome__button {
        margin: 0px 25px 0px 25px;
    }

    .hcphome__gray-container--4 {
        padding: 50px 150px 0px 150px;
    }

    .hcphome__extrapadding {
        padding: 0px 40px 0px 40px;
    }

    .hcphome .paragraph {
        line-height: 1.5;
    }
}

@media screen and (min-width: 1200px) {
    .hcphome__section {
        padding: 100px 100px;
    }

    .hcphome__gray-container {
        height: 600px;
    }

    .hcphome__circle-svg--1 {
        top: -60%;
        right: -23%;
        width: 50%;
        height: 150%;
    }

    .hcphome__circle-svg--2 {
        top: -60%;
        left: -23%;
        width: 50%;
        height: 150%;
    }

    .hcphome__gray-container--3 {
        height: 400px !important;
    }

    .hcphome__button-container--3 .hcphome__button {
        height: 50px;
    }

    .hcphome__section-header {
        font-size: 24px;
    }

    .hcphome__extrapadding {
        padding: 0px 60px 0px 60px;
    }

    .hcphome__subheader {
        width: 100%;
        font-size: 24px;
    }

    .hcphome ul li.paragraph {
        line-height: 2.5;
    }
}

@media screen and (min-width: 1800px) {
    .hcphome__section {
        padding: 100px 260px;
    }

    .hcphome__gray-container {
        height: 600px;
    }

    .hcphome__gray-container--3 {
        height: 600px;
    }

    .hcphome__circle-svg--1 {
        top: -85%;
        right: -23%;
        width: 50%;
        height: 200%;
    }

    .hcphome__circle-svg--2 {
        top: -85%;
        left: -23%;
        width: 50%;
        height: 200%;
    }

    .hcphome__gray-container--4 {
        height: 600px;
        padding: 0px 275px 0px 275px;
    }
}
