.paragraph.privacy-policy__paragraph {
  font-size: 12px;
}

.privacy-policy--li {
  color: #686868;
  font-size: 16px;
  margin-left: 20px;
}

.privacy-policy__anchor {
  color: #365b7e;
}

.header--primary.privacy-policy__header {
  color: #686868;
  text-transform: none;
  font-size: 16px;
}

.privacy-policy__table-container {
  overflow-x: auto;
}

.privacy-policy__table thead {
  font-size: 14px;
  color: #707070;
}

.privacy-policy__table tbody {
  font-size: 12px;
  color: #707070;
  position: relative;
  right: -2%;
}

.privacy-policy__table-row {
  height: 30px;
  vertical-align: baseline;
  width: auto;
  text-align: center;
}

.privacy-policy__table {
  position: relative;
  right: 5%;
  width: 100%;
}

.privacy-policy__table-row td {
  padding: 0.5rem;
}

.privacy-policy__span-bold {
  font-weight: 700;
}

.privacy-policy {
  margin-bottom: 3rem;
}

.privacy-policy .banner .banner__text {
  position: absolute;
  top: 12%;
}

@media screen and (min-width: 600px) {
  table.privacy-policy__table tbody {
    position: relative;
    right: -3%;
  }

  tr.privacy-policy__table-row td {
    padding: 1.5rem;
  }

  tr.privacy-policy__table-row td,
  tr.privacy-policy__table-row th {
    font-size: 16px;
  }

  .privacy-policy__disclaimer-container p.paragraph.privacy-policy__paragraph {
    font-size: 16px;
  }

  .header--primary.privacy-policy__header {
    font-size: 24px;
  }
}

@media screen and (min-width: 900px) {
  .privacy-policy .banner .banner__text {
    top: 50%;
    left: 30%;
  }
}

@media screen and (min-width: 1200px) {
  table tr.privacy-policy__table-row td {
    padding: 1.5rem;
  }
}

@media screen and (min-width: 1800px) {
  .privacy-policy__table-container table.privacy-policy__table tbody {
    position: relative;
    right: -4%;
  }

  table.privacy-policy__table {
    position: relative;
    right: 6%;
  }

  .privacy-policy .banner .banner__text .banner__text-bold {
    font-size: 75px;
  }

  .privacy-policy .banner .banner__text {
    left: 24%;
  }
}
