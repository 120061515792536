@media (min-width: 900px) {
  .clinicalstudies .banner .banner__text {
    top: 50%;
    left: 30%;
  }
}

@media screen and (min-width: 1200px) {
  .clinicalstudies .banner .banner__text {
    left: 28%;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
}
