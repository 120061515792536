.patients__img-container {
  width: 100%;
  text-align: center;
  margin: 30px 0px 30px 0;
}

.patients__img-container--1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.patients__img-container--3 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.patients__img-flex-item {
  margin-bottom: 30px;
}

.patients__img--1 {
  width: 200px;
  margin-bottom: 10px;
}

.patients__img--2 {
  width: 100%;
}

.patients__img--3 {
  width: 200px;
  margin-bottom: 10px;
}

.patients__img--4 {
  width: 100%;
}

.patients__two-columns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  width: 100%;
}

.patients__item-img {
  width: 200px;
  margin-bottom: 30px;
}

.patients__item-text {
  width: 100%;
}

.patients__item-header {
  color: #686868;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
}

.patients__img-caption {
  color: #686868;
  font-weight: 600;
  font-size: 18px;
}

.patients .banner .banner__text .banner__text-normal {
  font-size: 35px;
  font-weight: 100;
}

.patients .hcphome__button-container--3 .btn {
  margin: 0;
}

.patients__list {
  padding-left: 18px;
  margin-top: 30px;
}

.patients__list > li {
  color: #faa21c;
}

.patients__header-ref {
  font-size: initial;
  font-family: inherit;
}

.patients__section {
  display: flex;
  flex-direction: column;
  padding: 38px;
}

.patients__text-right {
  text-align: right;
  margin-top: -10%;
}

.patients__half-img > img {
  width: 35%;
}

.patients__flex {
  display: flex;
  flex-direction: column;
}

.patients__half-img.patients__half-img--2 {
  margin: 0;
}

@media screen and (min-width: 600px) {
  .patients .banner .banner__text .banner__text-normal {
    font-size: 45px;
  }

  .patients__img--1 {
    width: 250px;
  }

  .patients__img--3 {
    width: 250px;
  }

  .patients__item-img {
    width: 250px;
  }

  .patients__section {
    justify-content: flex-start;
    padding: 30px 100px;
  }
}

@media screen and (min-width: 900px) {
  .patients .banner .banner__text {
    text-align: left;
    top: 50%;
  }

  .patients .paragraph {
    line-height: 1.5;
  }

  .patients__img-container--1 {
    flex-direction: row;
  }

  .patients__img-container--3 {
    flex-direction: row;
    align-items: baseline;
  }

  .patients__two-columns {
    flex-direction: row;
    width: 100%;
  }

  .patients__img--2 {
    width: 50%;
  }

  .patients__img--1 {
    width: 150px;
  }

  .patients__img--3 {
    width: 150px;
  }

  .patients__item-img {
    width: 150px;
    margin-right: 80px;
    margin-bottom: 0;
  }

  .patients__img-caption {
    font-size: 13px;
  }

  .patients__two-columns--2 {
    margin-bottom: 120px;
  }

  .patients__section {
    /* justify-content: space-between; */
    padding: 30px 150px;
  }

  .patients__half-img {
    margin: 65px 0 0;
  }

  .patients__flex {
    flex-direction: row;
  }

  .mobile-only {
    display: none;
  }

  .patients__text-right {
    margin-top: 0;
  }

  .patients__half-img > img {
    width: 200px;
  }

  .br__mobile-only--patients {
    display: none;
  }

  .patients__half-img.patients__half-img--2 {
    display: flex;
    justify-content: center;
  }

  .banner__img.banner__img--desktop.patients__banner--desktop {
    height: 250px;
    object-fit: fill;
  }

  .patients__header-ref {
    font-size: 22px;
  }

  .content.hcphome__right-content {
    top: 12%;
  }
}

@media (min-width: 1200px) {
  .patients__img-container {
    flex-direction: row;
    height: auto;
  }

  .patients__img-flex-item {
    margin-bottom: 0px;
  }

  .patients__img--1 {
    width: 250px;
  }

  .patients__img--2 {
    width: 50%;
  }

  .patients__img--3 {
    width: 250px;
  }

  .patients__item-img {
    width: 18%;
    margin-right: 100px;
    margin-bottom: 0;
    width: 250px;
  }

  .patients__item-text {
    width: 40%;
  }

  .patients__img-caption {
    font-size: 21px;
  }

  .patients .banner .banner__text .banner__text-normal {
    font-size: 55px;
  }

  .patients__two-columns--2 {
    margin-bottom: 90px;
  }

  .patients__section {
    justify-content: space-evenly;
    padding: 50px 20px;
    flex-direction: row;
  }

  .patients__half-img {
    width: 50%;
  }

  .paragraph--width {
    width: 650px;
    float: right;
  }

  .patients__half-img > img {
    width: 250px;
  }

  .banner__img.banner__img--desktop.patients__banner--desktop {
    height: auto;
    object-fit: initial;
  }

  .hcphome__gray-container {
    height: 470px !important;
  }

  .patients__item-header {
    font-size: 24px;
  }

  .patients__header-ref {
    font-size: 23px;
  }

  .content.hcphome__right-content {
    top: 10%;
  }
}

@media (min-width: 1800px) {
  .patients__img--1 {
    width: 265px;
  }

  .patients__img--2 {
    width: 50%;
  }

  .patients__img--3 {
    width: 265px;
  }

  .patients__item-img {
    width: 265px;
  }

  .patients__item-header {
    font-size: 25px;
  }

  .patients__img-caption {
    font-size: 25px;
  }

  .patients .banner .banner__text .banner__text-normal {
    font-size: 64px;
  }

  .patients__img-container--1 {
    /* margin: 20px 0px 40px 0; */
    margin: 40px 0px 40px 0;
  }

  .patients__img-container--3 {
    /* margin: 40px 0px 20px 0; */
    margin: 40px 0px 40px 0;
  }

  .patients__two-columns--2 {
    margin-bottom: 120px;
  }

  .patients__half-img {
    width: 45%;
  }

  .patients__half-img > img {
    /* width: 65%; */
    width: 325px;
  }

  .patients__header-ref {
    font-size: 27px;
  }

  .content.hcphome__right-content {
    top: 8%;
  }
}
