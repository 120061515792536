.cookie-banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px 0px;
  position: fixed;
  bottom: 0;
  min-height: 37vh;
  width: 100%;
  z-index: -1;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
}

.cookie-banner-container.active {
  opacity: 1;
  z-index: 90;
}

.disclaimer-container {
  width: 87%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.disclaimer-header {
  font-family: "Comfortaa";
  font-size: 20px;
  color: #365b7e;
  font-weight: 900;
  margin: 5px 0px 10px 0px;
}

.disclaimer-description {
  font-size: 14px;
  line-height: 2;
}

.consent-options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 300px;
  margin: 30px 0px;
  padding: 0px 10px;
}

.consent-banner-button {
  cursor: pointer;
  border: none;
  /* padding: 10px 10px; */
  border-radius: 3px;
  font-size: 16px;
  height: 50px;
  font-weight: 600;
}

.consent-banner-button.reject-all {
  color: #365b7e;
  background-color: transparent;
  text-decoration-line: underline;
  width: 210px;
}

.consent-banner-button.accept-all {
  color: #ffffff;
  background-color: #365b7e;
  width: 125px;
}

@media screen and (min-width: 480px) {
  .cookie-banner-container {
    min-height: 33vh;
    flex-direction: column;
    align-items: center;
    padding: 10px 40px;
  }

  .disclaimer-header {
    font-size: 22px;
    margin: 25px 0px 25px 0px;
  }

  .consent-options-container {
    width: 400px;
  }

  .consent-banner-button.reject-all {
    width: 260px;
  }

  .consent-banner-button.accept-all {
    width: 130px;
  }
}

@media screen and (min-width: 768px) {
  .cookie-banner-container {
    min-height: 25vh;
  }

  .disclaimer-header {
    font-size: 24px;
    margin: 20px 0px 20px 0px;
  }

  .disclaimer-description {
    font-size: 16px;
  }

  .consent-options-container {
    width: 475px;
  }

  .consent-banner-button.reject-all {
    width: 280px;
  }

  .consent-banner-button.accept-all {
    width: 180px;
  }
}

@media screen and (min-width: 992px) {
  .cookie-banner-container {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    min-height: 20vh;
  }
  .disclaimer-container {
    display: block;
    width: 60%;
  }

  .disclaimer-header {
    margin: 5px 0px 5px 0px;
  }

  .consent-options-container {
    width: 350px;
    margin: 0px;
    padding: 0px;
  }
  .consent-banner-button.reject-all {
    width: 230px;
  }

  .consent-banner-button.accept-all {
    width: 110px;
  }
}

@media screen and (min-width: 1200px) {
  .cookie-banner-container {
    padding: 10px 40px;
  }
  .consent-options-container {
    width: 400px;
  }

  .consent-banner-button.reject-all {
    width: 250px;
  }

  .consent-banner-button.accept-all {
    width: 130px;
  }
}

@media screen and (min-width: 1400px) {
  .cookie-banner-container {
    min-height: 18vh;
  }

  .consent-banner-button.reject-all {
    width: 257px;
  }

  .consent-banner-button.accept-all {
    width: 125px;
  }
}
