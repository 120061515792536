.navbar {
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 90px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  backface-visibility: hidden;
  padding: 20px 30px 20px 30px;
  font-size: 15px;
}

.navbar__label {
  border-bottom: 2px solid transparent;
  text-decoration: none;
  color: #707070;
  display: block;
  transition: all 0.2s;
  font-weight: 300;
  display: flex;
  align-items: center;
}

.navbar__label:hover {
  color: #365b7e;
  border-bottom: 2px solid #365b7e;
}

.navbar__label.active {
  color: rgb(54, 91, 126);
  border-bottom: 2px solid rgb(54, 91, 126);
}

.navbar__dropdown .navbar__nav-links {
  border: none !important;
  margin-bottom: 15px;
}

.navbar__nav-links:visited {
  color: #707070;
}

.navbar__dropdown .navbar__nav-links:hover {
  color: #365b7e;
}

.navbar__dropdown .navbar__nav-links:active {
  color: #365b7e;
}

.navbar .navbar__dropdown {
  display: none;
  padding-top: 10px;
}

.navbar__side--left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar__nav-item {
  list-style-type: none;
}

.navbar__logo {
  width: 120px;
  display: block;
  position: relative;
}

.navbar__nav-item {
  margin: 15px;
}

.navbar__side--left {
  width: 100%;
  flex-direction: row-reverse;
}

.navbar__nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 90px;
  left: -100%;
  opacity: 1;
  transition: all 0.6s ease-out;
}

.navbar__nav-menu.active {
  background: #ffffff;
  left: 0;
  opacity: 1;
  transition: all 0.4s ease-in;
  z-index: 1;
}

.navbar__menu-icon {
  display: block;
  font-size: 25px;
  display: block;
  color: #707070;
  cursor: pointer;
}

.navbar__dropdown {
  padding-left: 30px;
}

.horizontal-line {
  width: 100%;
  border: 1px solid #ecefef;
}

.navbar__nav-item .navbar__nav-links {
  display: block;
  color: #707070;
  text-decoration: none;
}

input.navbar__checkbox[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.navbar .navbar__side--right {
  align-items: flex-start;
  padding: 0px 20px 0px 20px;
}

.navbar__checkbox ~ label .expansion-arrow {
  transition: all 0.4s;
}

.navbar__checkbox:checked ~ label .expansion-arrow {
  transform: rotate(-180deg) !important;
}

.navbar__checkbox:checked ~ div.navbar__dropdown {
  display: block !important;
  visibility: visible;
  animation: slideDown 0.3s ease-in-out;
  height: auto;
  opacity: 1;
}

.navbar__nav-item:hover .navbar__label {
  border-bottom: 2px solid #365b7e;
  color: #365b7e;
}

/* hamburger animation */
.navbar__side--left {
  position: relative;
}

.navigation__icon {
  position: relative;
}

.navigation__icon,
.navigation__icon::before,
.navigation__icon::after {
  content: "";
  position: absolute;
  left: 0;
  transition: all 0.2s;
  width: 35px;
  height: 2px;
  background-color: #707070;
  display: inline-block;
  cursor: pointer;
}

.navigation__icon::before {
  top: -12px;
}

.navigation__icon::after {
  top: 12px;
}

.navigation__checkbox {
  opacity: 0;
  position: absolute;
  top: 6px;
  left: 0;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.navigation__checkbox.active + .navigation__button .navigation__icon {
  background-color: transparent;
}

.navigation__checkbox.active + .navigation__button .navigation__icon::before {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox.active + .navigation__button .navigation__icon::after {
  top: 0;
  transform: rotate(-135deg);
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.expansion-arrow {
  stroke: #707070;
  stroke-width: 1.65;
  font-size: 13px;
  margin-left: 8px;
}

@media (min-width: 600px) {
  .navbar {
    font-size: 15px;
  }
  .navbar__nav-item {
    margin: 25px;
  }

  .navigation__icon,
  .navigation__icon::before,
  .navigation__icon::after {
    content: "";
    position: absolute;
    left: 0;
    transition: all 0.2s;
    width: 2.5rem;
    height: 2px;
    background-color: #707070;
    display: inline-block;
    cursor: pointer;
  }

  .navigation__icon::before {
    top: -0.8rem;
  }

  .navigation__icon::after {
    top: 0.8rem;
  }
}

@media (min-width: 900px) {
  .navbar {
    padding: 0px 50px 0px 50px;
  }

  .navbar__logo {
    width: 140px;
    display: block !important;
    position: relative !important;
    z-index: 9999;
  }

  .navbar .navbar__side--right {
    align-items: center;
    justify-content: flex-end;
  }

  .navbar .navbar__dropdown {
    position: absolute;
  }

  .navbar__label {
    width: max-content;
  }

  .navbar__side--left {
    flex-direction: initial;
  }

  .navbar__menu-icon {
    display: none;
    position: relative;
  }

  .navbar__nav-item {
    position: relative;
  }

  .navbar__nav-menu {
    position: initial;
    flex-direction: row;
    height: auto;
  }

  .navbar__dropdown {
    padding-left: 0;
  }

  .navbar__dropdown .navbar__nav-links {
    width: max-content;
  }

  .navbar .navbar__side--right {
    padding: 0px;
  }

  .navbar__checkbox:checked ~ div.navbar__dropdown {
    visibility: hidden;
  }

  .navbar__nav-item:hover > .navbar__dropdown {
    display: block;
    visibility: visible !important;
    opacity: 1 !important;
    animation: fadeInFromNone 0.6s ease-out !important;
    position: absolute;
    background-color: none;
    width: 100%;
    z-index: 9999;
  }

  .navbar__nav-item:hover > .navbar__dropdown .navbar__nav-links {
    opacity: 1;
    animation: fadeInFromNone 0.6s ease-out !important;
  }

  .horizontal-line {
    display: none;
    visibility: hidden;
  }

  .navbar__checkbox:checked ~ label .expansion-arrow {
    transform: rotate(0) !important;
  }

  .navbar__checkbox:hover ~ label .expansion-arrow {
    transform: rotate(-180deg) !important;
  }

  .navbar__nav-item--2::before,
  .navbar__nav-item--3::before,
  .navbar__nav-item--4::before {
    content: "";
    display: block;
    position: fixed;
    width: 1000000000000px;
    height: 325px;
    background: #ffffff;
    top: -500px;
    left: 0;
    transition: 0.3s ease-in-out;
    z-index: -9999;
    backface-visibility: hidden;
  }

  .navbar__nav-item--2:hover::before {
    top: -150px;
  }

  .navbar__nav-item--3:hover::before,
  .navbar__nav-item--4:hover::before {
    top: -180px;
  }

  .navbar__nav-item:hover label .expansion-arrow {
    transform: rotate(-180deg) !important;
  }

  .navigation__checkbox {
    opacity: 0;
    display: none;
    visibility: hidden;
  }
}

@media (min-width: 1200px) {
  .navbar {
    padding: 0px 100px 0px 100px;
  }

  .navbar__nav-item--2:hover::before {
    top: -150px;
  }

  .navbar__nav-item--3:hover::before,
  .navbar__nav-item--4:hover::before {
    top: -180px;
  }
}
