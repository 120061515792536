.testimonalquote {
  position: relative;
  font-size: 16px;
  margin-bottom: 25px;
  color: #707070;
}

.testimonalquote__content {
  font-weight: 700;
  margin: 0 auto;
  margin-bottom: 30px;
  line-height: 2;
}

.testimonalquote__content::before {
  content: open-quote;
}

.testimonalquote__content::after {
  content: close-quote;
}

.testimonalquote__reference {
  text-align: right;
}

.testimonalquote__name {
  text-align: right;
  font-weight: 700;
}

.testimonalquote__credential {
  font-style: italic;
}

@media screen and (min-width: 1200px) {
  .testimonalquote {
    font-size: 25px;
  }
}
