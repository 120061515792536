.patient__video-container--1,
.patient__video-container--2 {
  margin-top: 50px;
}

.testimonial__content--2 {
  margin-bottom: 3rem;
}

@media screen and (min-width: 900px) {
  .testimonial__content--2 {
    width: 48%;
  }

  .patient__video-container--2 {
    display: flex;
    justify-content: space-between;
    margin-top: 100px;
  }

  .patients__div-review {
    padding: 10px 40px;
    margin: 0 auto;
  }

  #first-patient-testimonial {
    margin-top: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .patients__div-review {
    padding: 20px 60px;
    margin: 0 auto;
  }

  #first-patient-testimonial {
    margin-top: 45px;
  }
}
