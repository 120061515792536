.slide--content {
display: flex;
flex-direction: column;
align-items: center;
}

.carousel {
display: flex;
justify-content: space-evenly;
width: 100%;
margin: 0 auto;
position: relative;
}

.slide {
display: flex;
scale: 0.8;
transition: transform 0.5s ease-in-out;
}

.slide.slide--hidden {
display: none !important;
visibility: hidden;
}

.arrow {
height: 25px;
position: absolute;
z-index: 2;
bottom: -15px;
}

.arrow--left {
left: 20%;
}

.arrow--right {
right: 20%;
}

.indicators {
display: flex;
justify-content: space-evenly;
width: 90px;
margin: 25px auto;
}

.indicator {
box-shadow: 0px 0px 0px #555;
background-color: #FFFFFF;
height: 0.5rem;
width: 0.55rem;
border-radius: 100%;
border: 1px solid #D9D9D9;
}

.indicator.indicator--inactive {
background-color: #D9D9D9;
}

.carousel--3D {
overflow: initial;
}

.patients__half-img {
margin: 35px 0 0;
}

div.carousel--3D div:nth-child(3) {
scale: 1.2;
}

.slide--content > img {
height: 85px;
}

.carousel__button {
border: none;
}

.carousel__description--inactive {
display: none;
}

.carousel__description {
margin-top: 15px;
color: #686868;
font-weight: 600;
font-size: 10px;
}

.slide--content--1 {
    /* height: 485px; */
    justify-content: space-between;
}

.slide--content-image {
    /* width: 85%; */
    width: 250px;
}

.slide--content-text {
    width: 100%;
    text-align: center;
}

.arrow--left.arrow--left--1 {
    left: 0;
    top: 27%;
    height: 40px;
}

.arrow--right.arrow--right--1 {
    right: 0;
    top: 27%;
    height: 40px;
}

.content.content--carousel {
    margin-top: 0;
    margin-bottom: 0;
}


@media (min-width: 600px) {
    .slide {
     scale: 0.9;
    }

    .carousel__description {
     font-size: 11px;
    }

    .content.content--carousel {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .slide--content-image {
        width: 200px;
    }

    .slide--content > img {
        height: 95px;
        }
}

@media (min-width: 900px) {
  .carousel {
     width: 100%
    }

   .arrow--left {
     left: 30%;
    }
        
    .arrow--right {
     right: 30%;
    }

    .arrow {
     bottom: -20px;
    }

    .slide--content > img {
     height: 120px;
    }

    .content.content--carousel {
        margin-top: 0px;
    }

    .arrow--left.arrow--left--1 {
        left: -100px;
        top: 30%;
    }
    
    .arrow--right.arrow--right--1 {
        right: -100px;
        top: 30%;
        left: auto;
    }

    .slide--content-text {
        width: 55%;
        text-align: initial;
    }

    .slide--content--1 {
        /* height: 485px; */
        flex-direction: row;
        justify-content: space-around;
        flex-grow: 1;
    }

    .carousel__description {
        margin-top: 20px;
    }
    
}

@media (min-width: 1200px) {
.carousel {
justify-content: center;
}

.slide--content > img {
    height: 180px;
}

.arrow--left {
    left: 0;
}

.carousel__description {
    font-size: 15px;
}

.arrow--right {
    right: 0;
}

.arrow {
    bottom: -5px;
    height: 30px;
}

.indicators {
    width: 150px;
    margin: 30px auto;
}

.indicator {
    height: 0.7rem;
    width: 0.75rem;
}

.carousel {
    width: 280px;
}

div.carousel--3D div:nth-child(3) {
    scale: 1;
}

.slide {
    scale: 0.7;
    width: 100%;
   }

.carousel--1 {
    width: 100%;
}

.slide--content-image {
    width: 300px;
}

.slide--content-text {
    width: 50%;
}

.arrow--left.arrow--left--1 {
    left: 0;
    top: 35%;
    height: 45px;
}

.arrow--right.arrow--right--1 {
    right: 0;
    top: 35%;
    height: 45px;
}
}

@media (min-width: 1800px) {

.slide--content > img {
    height: 265px;
}

.arrow--left {
    left: 0;
}

.arrow--right {
    left: 260px;
}

.slide--content-image {
    width: 350px;
}

.carousel__description {
    font-size: 17px;
}

}