.footer-container {
  display: flex;
  justify-content: space-around;
  background-color: #eeeeee;
  height: 100px;
}

.footer-link span a {
  text-decoration: none;
  color: #707070;
  font-weight: 600;
}

.footer-link {
  display: flex;
  flex-direction: column;
}

.footer-container {
  flex-direction: column;
  height: 225px;
  padding: 50px;
}

span.hide-mobile {
  visibility: hidden;
  display: none;
}

.footer-copyright span,
.footer-link span {
  font-size: 11px;
  color: #707070;
}

span.border {
  border-bottom: 1px solid #c7c7c7;
}

.footer-link span {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

@media screen and (min-width: 900px) {
  .footer-link {
    display: flex;
    flex-direction: row;
  }

  .footer-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: auto;
    flex-direction: row;
    align-items: center;
    height: 90px;
    padding: 25px 0px;
  }

  .footer-link,
  .footer-copyright {
    display: inline-block !important;
  }

  span.hide-mobile {
    visibility: visible;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .footer-copyright span,
  .footer-link span {
    font-size: 12px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  span.border {
    border-bottom: none;
  }
}

@media screen and (min-width: 1200px) {
  .footer-copyright span,
  .footer-link span {
    font-size: 13px;
  }
}
