.terms__anchor {
  color: #365b7e;
}

.header-primary.terms__header {
  color: #686868;
  text-transform: none;
  font-size: 16px;
}

.terms {
  margin-bottom: 3rem;
}

.terms .banner .banner__text {
  position: absolute;
  top: 12%;
}

.paragraph.terms__paragraph {
  font-size: 12px;
}

@media screen and (min-width: 600px) {
  .header-primary.terms__header {
    font-size: 24px;
  }

  .paragraph.terms__paragraph {
    font-size: 16px;
  }
}

@media screen and (min-width: 900px) {
  .terms .banner .banner__text {
    top: 50%;
    left: 30%;
  }
}

@media screen and (min-width: 1200px) {
  .terms .banner .banner__text {
    left: 27%;
  }
}

@media screen and (min-width: 1800px) {
  .terms .banner .banner__text {
    top: 50%;
    left: 24%;
  }
}
