.redirect .banner .banner__text {
  text-align: center;
  position: absolute;
  top: 25%;
}

.redirect__anchor {
  color: #365b7e;
}

.redirect__bold-text {
  font-weight: 700;
}

.redirect .banner .banner__text .banner__text-normal {
  font-size: 21px;
  font-weight: 700;
}

.redirect .banner .banner__text .banner__text-html {
  font-size: 15px;
  padding: 0 30px 0 30px;
}

@media screen and (min-width: 600px) {
  .redirect .banner .banner__text span.banner__text-normal {
    font-size: 27px;
  }

  .redirect .banner .banner__text span.banner__text-html {
    font-size: 20px;
  }

  .redirect .banner .banner__text .banner__text-html {
    padding: 0 120px 0 120px;
  }
}

@media screen and (min-width: 900px) {
  .redirect .banner .banner__text {
    top: 40%;
    left: 40%;
    text-align: left;
  }

  .redirect .banner {
    position: static;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  .redirect .banner .banner__text span.banner__text-html {
    font-size: 20px;
    padding: 0px;
  }

  .redirect .banner .banner__text span.banner__text-normal {
    font-size: 27px;
  }

  .redirect .banner .banner__img--desktop {
    min-width: 900px;
    min-height: 100%;
    height: auto;
    position: fixed;
    left: 0;
    image-rendering: -webkit-optimize-contrast;
  }

  .redirect + .footer-container {
    position: fixed;
    bottom: 0;
  }
}

@media screen and (min-width: 1200px) {
  .redirect .banner .banner__text {
    text-align: left;
  }

  .redirect .banner .banner__text {
    left: 30%;
  }

  .redirect .banner .banner__text span.banner__text-normal {
    font-size: 32px;
  }
}

@media screen and (min-width: 1800px) {
  .redirect .banner .banner__text span.banner__text-html {
    font-size: 22px;
  }

  .redirect .banner .banner__text span.banner__text-normal {
    font-size: 38px;
  }
}
