.contact-us__input-container {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
}

.contact-us__button {
  text-align: center;
  margin: 3rem 0;
  display: flex;
  justify-content: center;
}

.contact-us__button button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background-color: #365b7e;
  color: #ffffff;
  font-size: 17px;
  width: 100px;
  height: 45px;
  font-weight: 600;
}

.contact-us__input {
  border: none;
  background-color: #eeeeee;
  height: 35px;
  border-radius: 3px;
  padding: 10px;
}

.contact-us__textarea {
  border: none;
  height: 150px;
  background-color: #eeeeee;
  border-radius: 3px;
  resize: none;
  padding: 10px;
}

.contact-us__label {
  margin-bottom: 0.75rem;
  color: #707070;
  font-size: 15px;
}

.contact-us__span-required {
  font-style: italic;
}

.paragraph.contact-us__paragraph {
  font-size: 12px;
  margin: 20px 0px;
}

.contact-us__anchor {
  color: #365b7e;
}

.paragraph.contact-us__paragraph--disclaimer {
  margin-bottom: 40px;
  font-size: 10px;
}

.validate {
  animation: validating 4s ease-in-out alternate infinite;
  color: transparent;
}

/* Success Animation */

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #4bb71b;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #4bb71b;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  position: relative;
  top: 5px;
  right: 5px;
  margin: 0 auto;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #4bb71b;
  fill: #fff;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #4bb71b;
  }
}

@keyframes validating {
  0% {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid;
    border-left-color: #1b3b5a;
    transform: rotate(0deg);
  }

  50% {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid;
    border-left-color: #1b3b5a;
    transform: rotate(1440deg);
  }

  100% {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 3px solid;
    border-left-color: #1b3b5a;
    transform: rotate(0deg);
  }
}

@media screen and (min-width: 900px) {
  .contact-us__input {
    height: 35px;
  }

  .contact-us__textarea {
    height: 120px;
  }

  .contact-us__button button {
    font-size: 20px;
    width: 130px;
    height: 50px;
  }

  .paragraph.contact-us__paragraph--disclaimer {
    margin-bottom: 80px;
    font-size: 12px;
  }

  .paragraph.contact-us__paragraph {
    margin-bottom: 30px;
    font-size: 15px;
  }

  .contact-us .content {
    margin-top: 90px;
  }

  .contact-us__label {
    font-size: 18px;
  }

  .contact-us .banner .banner__text {
    top: 50%;
    left: 27%;
  }
}

@media screen and (min-width: 1200px) {
  .contact-us .banner .banner__text {
    left: 24%;
  }
}
