:root {
  --primary: #365b7e;
}

.btn {
  background-color: var(--primary);
  border: none;
  padding: 10px 20px;
  color: #ffffff;
  font-size: 15px !important;
  border-radius: 3px;
  height: 40px !important;
  margin-bottom: 10px;
  font-weight: 600 !important;
  cursor: pointer;
  min-width: max-content;
  transition: all 0.3s;
}

.btn:hover {
  background-color: #1b3b5a !important;
}

.btn:focus {
  background-color: #1b3b5a !important;
  outline: none !important;
}

.btn-link {
  text-decoration: none;
}

@media (min-width: 1200px) {
  .btn {
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 10px 20px;
    color: #ffffff;
    font-size: 18px !important;
    border-radius: 3px;
    height: 50px !important;
    margin-bottom: 10px;
    font-weight: 600;
    cursor: pointer;
  }
}
