/* Blurry background effect when modal is open */
.content.blur,
.EUBanner.blur {
    filter: blur(10px);
    transition: filter 0.3s ease-in-out;
    pointer-events: none; /* Prevent interactions with blurred content */
}

/* Modal overlay to dim the background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal container for the content */
.modal-container {
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 400px;
    max-width: 90%;
    text-align: center;
    position: relative;
    z-index: 1100; /* Ensure the modal is above the blurred content */
}

/* Close button */
.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}

/* Modal text */
.modal-text {
    margin: 20px 0;
    font-size: 16px;
    color: #333;
}

/* Proceed button */
.modal-proceed-button {
    background-color: #365b7e;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.modal-proceed-button:hover {
    background-color: #2b4a63;
}
